import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  EditSessionContentsText,
  SessionContentsZoneController,
} from "../../../ludzoneSDK/Zone/Session/InsiderSession/controller";
import { SessionZoneController } from "../../../ludzoneSDK/Zone/Session/controller";
import {
  PropsChangeIndexSession,
  PropsGetBffSession,
  PropsSession,
} from "../../../ludzoneSDK/Zone/Session/type";
import { AllContentSessions } from "./type";
import { SessionWatchedZoneController } from "../../../ludzoneSDK/Zone/Session/WatchedSessionContents/controller";
import { Toast } from "primereact/toast";
import { useFetchZoneSessionsBff } from "../../../ludzoneSDK/Zone/Session/query";
import { PropsChangeIndexSessionContent } from "../../../ludzoneSDK/Zone/Session/InsiderSession/type";

export const SessionZoneState = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [session, setSession] = useState<PropsGetBffSession[]>();
  const [loading, setLoading] = useState(true)
  const [allContentSession, setallContentSession] =
    useState<AllContentSessions[]>();

  const [accordionDisa, setAccordionDisa] = useState(true);

  const idzona = parseInt(id!)

  const initialValues = {
    name: "",
    isToShow: false,
    index: 0,
    zone: 0,
  };

  const initialValuesContent = {
    textContent: "Escreva aqui",
    isToShow: false,
    title: "",
    video_url: "",
    index: "0",
    session: 0,
  };

  const schema = Yup.object().shape({
    title: Yup.string().required("O campo obrigatório"),
  });

  const toast = useRef<Toast>(null)

  const { data: sessionsRequest, refetch } = useFetchZoneSessionsBff(idzona)

  const {
    SessionZoneMutation,
    DeleteSessionContentsZoneMutation,
    DeleteSessionZoneMutation,
    UpdateSessionZoneMutation,
    ChangeIndexSessionZoneMutation
  } = SessionZoneController(parseInt(id!), { toast: toast });

  const handleChangeIndexSession = (body: PropsChangeIndexSession) => {
    ChangeIndexSessionZoneMutation.mutate(body)
  }

  const toastwatched = useRef<Toast>(null)

  const {
    DeleteSessionWatchedContentsZoneMutation,
    SessionWatchedZoneMutation,
  } = SessionWatchedZoneController({ toast: toastwatched });

  const toastarchives = useRef<Toast>(null)

  const { SessionContentsZoneMutation, ChangeIndexSessionContentZoneMutation } = SessionContentsZoneController({ toast: toastarchives }, idzona);


  const handleChangeIndexSessionContent = (body: PropsChangeIndexSessionContent) => {
    ChangeIndexSessionContentZoneMutation.mutate(body)
  }

  useEffect(() => {
    if (sessionsRequest) {
      setSession(sessionsRequest);
    }
  }, [sessionsRequest]);


  useEffect(() => {
    var array: Array<AllContentSessions> = [];
    if (loading && session) {

      session?.forEach((element) => {
        element.contents.forEach((i) => {
          array.push({ id: i.id });
        });
      });
      setallContentSession(array);
      setLoading(false)
    }
  }, [session, loading])


  const handleSession = (
    data: PropsSession,
    id: number,
    setModal: (boolean: boolean) => void
  ) => {
    setSession([
      ...session!,
      {
        name: data.name,
        isToShow: false,
        id: 9999999,
        contents: [],
        index: data.index,
        zone_fk: id,
      },
    ]);
    SessionZoneMutation.mutate({ ...data, zone: id });
    setModal(false);
  };

  const deleteSessionContent = (id: string, idzone: any) => {
    DeleteSessionContentsZoneMutation.mutate(id);
    history(`/zona/${idzone}/timeline/`);
  };

  const deleteSessionContentWatched = (id: string) => {
    DeleteSessionWatchedContentsZoneMutation.mutate(id);
  };

  const handleWatched = (id: string) => {
    SessionWatchedZoneMutation.mutate({ sessionContentId: parseInt(id) });
  };

  const handleSessionContentsFile = async (
    id: number,
    index: number,
    file: File | undefined,
    setModal: (boolean: boolean) => void,
    title: string,
    textContent?: string,
    playlist_content?: number,
    video_url?: string
  ) => {
    const typeContent = 1;
    if (file) {
      const form = new FormData();
      form.append("session", id.toString());
      form.append("index", index.toString());
      form.append("contentType", typeContent.toString());
      form.append("isToShow", "" + false);
      form.append("title", title);

      const arq = file;
      form.append("file", arq);
      SessionContentsZoneMutation.mutate({
        data: {
          index: index.toString(),
          session: id,
          contentType: typeContent,
          isToShow: false,
          title: title,
        },
        file: file,
      });
      setModal(false);
    }
    if (!file) {
      console.log(file)
      SessionContentsZoneMutation.mutate({
        data: {
          title: title,
          session: id,
          index: index.toString(),
          textContent: textContent,
          video_url: video_url,
          isToShow: false,
          playlist_content: playlist_content,
        }
      });
      setModal(false);
    }

    setTimeout(() => {
      refetch();
    }, 300);
  };



  const { EditSessionContentsZoneMutation } = EditSessionContentsText();

  const handleEditSessionContent = (
    data: { textContent?: string | null; title?: string },
    id: number
  ) => {
    EditSessionContentsZoneMutation.mutate({ body: data, id: id });
  };

  const handleEditSession = (data: { name: string }, id: string) => {

    UpdateSessionZoneMutation.mutate({ data: data, id: id });
    
  };

  const DeleteSession = (id: number) => {
    const updatedSession = session!.filter((obj) => obj.id !== id);
    setSession(updatedSession);
    DeleteSessionZoneMutation.mutate(id.toString());
  };

  return {
    handleSession,
    initialValues,
    session,
    initialValuesContent,
    handleSessionContentsFile,
    DeleteSession,
    schema,
    deleteSessionContent,
    handleEditSessionContent,
    handleEditSession,
    accordionDisa,
    setAccordionDisa,
    allContentSession,
    deleteSessionContentWatched,
    handleWatched,
    toast,
    toastwatched,
    toastarchives,
    handleChangeIndexSession,
    handleChangeIndexSessionContent
  };
};
