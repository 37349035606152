import { Avatar, Input } from "../..";
import { Column } from "../../../styles/globalStyles";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DropdownMenu } from "./MentionListStyles";
import { useMentionStore } from "../../../zustand/Mentions/zustand";
import AvatarEveryone from "../../../assets/images/Logo 3.png";

interface MentionListProps {
  items: any[];
  command: (item: { id: string }) => void;
  members: any[];
  value: string;
  onChange: (key: string, value: any) => void;
  show?: any
}

export interface MentionListHandle {
  onKeyDown: (options: { event: KeyboardEvent }) => boolean;
}

const MentionList = forwardRef<MentionListHandle, MentionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [search, setSearch] = useState("");

    const addIds = useMentionStore((state) => state.addId)
    const ids = useMentionStore((state) => state.ids);

    var userFilter = props.items;
    if (search !== "") {
      const buscaLowerCase = search.toLowerCase();
      userFilter = props.items?.filter((members: any) =>
        members?.label.toLowerCase().includes(buscaLowerCase)
      );
    }

    
    const markUser = (username: string, id: number) => {

        if (!ids?.includes(id)) {
          addIds(id)
        props.onChange("mentions", ids);
        props.onChange(
          "content",
          `${props.value.substring(0, props.value.length - 1)} @${username}`
        );
      

        props.command({ id: username });
        }
        else{
          props?.show()
        }
      


        console.log("UpdatedIds no componente MentionsList", ids)
      };
      

    const selectItem = (index: number) => {
      const item = userFilter[index];
      if (item) {
        markUser(item.label, item.id);
      }
    };

    

    
    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + userFilter.length - 1) % userFilter.length
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % userFilter.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => {
      setSelectedIndex(0);
    }, [userFilter]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <DropdownMenu>
        <Input
          placeholder="Digitar usuário"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <hr style={{ width: "100%", marginTop: "10px" }} />
        <h5 className="mb-1">Membros</h5>
        {userFilter.length ? (
          <>
            {userFilter.map((item, index) => (
              <button
                className={index === selectedIndex ? "is-selected" : ""}
                key={index}
                onClick={() => markUser(item.label, item.id)}
                style={{ cursor: "pointer" }}
              >
                <Avatar shape="circle" size="normal" img={`${item.avatar ? item.avatar : AvatarEveryone}`} />
                <Column>
                  <p>{item.label}</p>
                </Column>
              </button>
            ))}
          </>
        ) : (
          <div className="item">Nenhum resultado</div>
        )}
      </DropdownMenu>
    );
  }
);

export default MentionList;
