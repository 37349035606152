import styled from "styled-components";
import color from "../../../ludz.one_ui/styles/colors";

export const Container = styled.div`
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: ${color.grayClearOneHover};
    }
    img {
        height: 32px;
        width: 32px;
        border-radius: 32px;
    }
`