import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Calendar,
  ModalInsiderKanbanCard,
} from "../../../../../../../../ludz.one_ui";

import * as C from "../style";
import { useMediaQuery } from "@mui/material";
import { Padding } from "../../../../../../../../ludz.one_ui/styles/styled-components";

// import { Container } from './styles';

interface PropsModalDate {
  visible: boolean;
  setvisible: any;
  handleUpdateDate: any;
  dateTime: any;
}

const ModalDate = ({
  visible,
  setvisible,
  handleUpdateDate,
  dateTime,
}: PropsModalDate) => {
  const { idcard } = useParams();


  const [date, setDate] = useState(dateTime ?? new Date(Date.now()));
  const matchesMobileSmall = useMediaQuery("(max-width: 380px)");

  const data = new Date(`${dateTime}`);

  let dia = data.getDate(); // retorna o dia do mês
  let mes = data.getMonth() + 1; // retorna o mês (0 é Janeiro, então adicionamos 1)
  let ano = data.getFullYear(); // retorna o ano

  // Adiciona um zero à esquerda se o dia ou o mês for menor que 10
  if (dia < 10) dia = 0 + dia;
  if (mes < 10) mes = 0 + mes;

  let dataFormatada = dia + "/" + mes + "/" + ano;

    // Função para desativar datas anteriores à data atual
    // const isDateDisabled = (date: Date): boolean => {
    //   const currentDate = new Date();
    //   currentDate.setHours(0, 0, 0, 0); // Zera as horas para comparar apenas a parte da data
    //   return date < currentDate;
    // };




  return (
    <ModalInsiderKanbanCard
      visible={visible}
      title={"Data de Entrega"}
      onOpen={() => setvisible(!visible)}
    >
      <C.AreaDateModal>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Calendar
            placeholder={dataFormatada}
            date={date}
            minDate={new Date()}
            dateFormat="dd/mm/yy"
            onChange={(e: any) =>  setDate(new Date(e.value))}
            style={{ width:matchesMobileSmall ? "80%" : "60%", margin: "auto", marginBottom: '5px' }}
          />
        </div>
        <Padding />
        <div
          style={{
            margin: "2.5%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            title="Salvar"
            onClick={() => {
              handleUpdateDate({ expectedDate: date }, parseInt(idcard!));
              setvisible(!visible);
            }}
            style={{ marginRight: '10px' }}
          />
          <Button
            title="Remover"
            severity="danger"
            onClick={() => {
              handleUpdateDate({ expectedDate: null }, parseInt(idcard!));
              setvisible(!visible);
            }}
          />
        </div>
      </C.AreaDateModal>
    </ModalInsiderKanbanCard>
  );
};

export default ModalDate;
