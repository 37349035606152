import styled from "styled-components";
import styles from "../styles";

export const Container = styled.div`
  border: 1px solid ${styles.colors.grayClearOneHover};
  border-radius: 8px;
  padding: 16px;
  .BubbleMenu {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: ${styles.colors.grayClearOne};
    border-radius: 8px;
    display: flex;
    align-self: center;
  }

  /* Basic editor styles */
  .tiptap {
    min-height: 64px;

     span.mention {
      border-radius: 0.4rem;
      box-decoration-break: clone;
      color: #1C82D5;
      font-weight: 600;
    }

    &:focus-visible {
      outline: none;
    }

    > * + * {
      margin-top: 0.75em;
    }

    ul[data-type="taskList"] {
      list-style: none;
      padding: 0;

      p {
        margin: 0;
      }

      li {
        display: flex;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
          user-select: none;
        }

        > div {
          flex: 1 1 auto;
        }

        ul li,
        ol li {
          display: list-item;
        }

        ul[data-type="taskList"] > li {
          display: flex;
        }
      }
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background: rgba(#ffffff, 0.1);
      color: rgba(#ffffff, 0.6);
      border: 1px solid rgba(#ffffff, 0.1);
      border-radius: 0.5rem;
      padding: 0.2rem;
    }

    pre {
      background: rgba(#ffffff, 0.1);
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
        border: none;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin-left: 0;
      padding-left: 1rem;
      border-left: 2px solid rgba(#ffffff, 0.4);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#ffffff, 0.1);
      margin: 2rem 0;
    }
  }
`;
