import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AreaAvatar, Container, ImageRoom, Indicator } from "./style";
import LudzTooltip from "../../../ludz.one_ui/Tooltip";
import { useEffect, useState } from "react";

interface PropsZonaMenu {
  zona?: any;
}

const ZonaMenu = ({ zona }: PropsZonaMenu) => {
  const [selectedZone, setSelectedZone] = useState<string | null | undefined>(null);
  let history = useNavigate();

  const { id } = useParams();
  const location = useLocation();

  function linkHistory() {
    history(`/zona/${zona.id ? zona.id : zona.zone.id}/painel`);
    setSelectedZone(`${zona.id || zona.zone.id}`)
  }

  useEffect(() => {
    setSelectedZone(id)
  },[id])

  const canal = location.pathname.includes('/canal');
  const perfil = location.pathname.includes('/perfil');
  const comprazona = location.pathname.includes('/comprazonas');
  const compracanal = location.pathname.includes('/compracanal');

  return (
    <Container>
     
      <LudzTooltip title={zona?.zone?.name ?? zona?.name} placement="right" arrow>
        <AreaAvatar>
        {(selectedZone && !canal && !perfil && !comprazona && !compracanal) && <Indicator />}
        <ImageRoom onClick={linkHistory}>
        
          <img src={zona!.zone ? zona?.zone?.aws?.url : zona?.aws?.url} alt="" />
        </ImageRoom>
        </AreaAvatar>
        
      </LudzTooltip>
    </Container>
  );
};

export default ZonaMenu;
