import React, { useState } from 'react';
import { CustomAccordion, CustomAccordionContent, CustomAccordionHeader } from './styles';

const Accordion = ({ title, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomAccordion>
      <CustomAccordionHeader onClick={toggleAccordion}>
      <i className={isOpen ? "pi pi-chevron-down" : "pi pi-chevron-right"} />
        <h3>{title}</h3>
        
      </CustomAccordionHeader>
      {isOpen && (
        <CustomAccordionContent>
          {children}
        </CustomAccordionContent>
      )}
    </CustomAccordion>
  );
};

export default Accordion;