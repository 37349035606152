import { useParams } from "react-router-dom";
import { MuralZoneController } from "../../../ludzoneSDK/Zone/PostMural/controller"
import { PropsZonePost, PropsZonePostComment, ZonaTypePost, ZonaTypePostComment } from "../../../ludzoneSDK/Zone/PostMural/type"
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react'
import { Toast } from "primereact/toast";


export const MuralZoneState = () => {

  const { id } = useParams()

  const [posts, setPosts] = useState()
  const [file, setFile] = useState();


  const toast = useRef<Toast>(null)

  const { PostZoneMutation, postsRequests, UpdateZonePostMutation, DeletePostZoneMutation, PostZoneCommentMutation, UpdateZonePostCommentMutation, DeletePostCommentZoneMutation } = MuralZoneController(parseInt(id!), {toast: toast})

  const initialValue: PropsZonePost = {
    content: "",
    mentions: [],
    zone: 0
  }

  const initialValueEdit: ZonaTypePost = {
    content: ""
  }

  const initialValueComment: ZonaTypePostComment = {
    content: "",
    mentions: []
  }

  useEffect(() => {
    if (postsRequests) {
      setPosts(postsRequests)
    }
  }, [postsRequests])


  const schema = Yup.object().shape({
    content: Yup.string().required('A descrição é obrigatória'),
  });

  const handlePost = (data: PropsZonePost, id: number) => {
      PostZoneMutation.mutate({ body: {content: data.content.toString(), mentions: data.mentions, zone: id}, file: file })
  }

  const handleEditPost = (idpost: string, body: ZonaTypePost, setEdit: any) => {
    UpdateZonePostMutation.mutate({ body: body, id: idpost! });
    setEdit(false);
  
  }

  const handleDeletePost = (idpost: any) => {
    
    DeletePostZoneMutation.mutate({ id: idpost.idpost.toString() });
  }

// Estados de comentários

  const handleSendComments = (idpost: number, data: PropsZonePostComment) => {
    const body = {
      zonePost: idpost,
      content: data.content,
      mentions: data.mentions
    }


    PostZoneCommentMutation.mutate(body);
  }

  const handleEditPostComment = (id: string, body: ZonaTypePostComment, setEdit: any) => {
    UpdateZonePostCommentMutation.mutate({ body: body, id: id! });
    setEdit(false);
  }

  const handleDeletePostComment = (id: any) => {

    DeletePostCommentZoneMutation.mutate({ id: id.id.toString() });
  }

  return {
    handlePost, initialValue, schema, posts, file, setFile, initialValueEdit, handleEditPost,  handleDeletePost, handleSendComments, initialValueComment, handleEditPostComment, handleDeletePostComment, toast
  }
}