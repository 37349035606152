import { useContext, useEffect, useState } from "react";
import { PrivateRoutesContext } from "../../../../../../../contexts/PrivateRoutes/context";
import {
  ChannelUser,
  PrivateRoutesContextType,
} from "../../../../../../../contexts/PrivateRoutes/types";
import color from "../../../../../../../ludz.one_ui/styles/colors";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import {
  useFetchUserChannel
} from "../../../../../../../ludzoneSDK/User/query";
import { Row } from "../../../../../../../styles/globalStyles";
import { CreateContent, CreateContentContext } from "../CreateContent/context";
import PlayLists from "./Playlists";
import Accordion from "./Accordion";

const VideoListState = () => {
  const [channelUser, setchannelUser] = useState<
    Array<ChannelUser> | undefined
  >();
  const { data: channelUserRequest } = useFetchUserChannel();

  useEffect(() => {
    if (channelUserRequest) {
      setchannelUser(channelUserRequest);
    }
  }, [channelUserRequest]);

  return { channelUser };
};

const VideoList = () => {
  const props = VideoListState();
  const { video } = useContext(CreateContentContext) as CreateContent;
  const propsprivate = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  return (
    <>
      <h3>Canais</h3>
      <Padding />

      {video ? (
        <Row>
          <h4>Video selecionado: </h4>
          <h4 style={{ color: color.secondary }}>{video?.name}</h4>
        </Row>
      ) : null}
      <Padding />

      {props.channelUser?.filter(props => props.channel.youtubeChannelId === null).map((item, index) => {
        return (
          // <div key={index}>
          //   <h4>{item!.channel.name}</h4>
          //   <Padding />
          //   <PlayLists item={item} />
          // </div>
          <Accordion key={`user-channel-${index}`} title={item!.channel.name}>
          <PlayLists item={item} />
        </Accordion>
        );
      })}
      {propsprivate.userProvider?.role === "BUSINESS" ? (
        <>
          {propsprivate.userBusinessChannel?.filter(props => props.youtubeChannelId === null).map((item, index) => {
            return (
              // <div key={index}>
              //   <h4>{item!.name}</h4>
              //   <Padding />
              //   <PlayLists item={item} />
              // </div>
              <Accordion key={`business-channel-${index}`} title={item!.name}>
            <PlayLists item={item} />
          </Accordion>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default VideoList;
