import create from "zustand";

interface MentionStore {
  ids: number[];
  addId: (id: number) => void;
  removeId: (id: number) => void;
  clearIds: () => void;
  
}

export const useMentionStore = create<MentionStore>((set) => ({
  ids: [],
  addId: (id) =>
    set((state: any) => {
        const updatedIds = state.ids
        updatedIds.push(id)
        return updatedIds
    }),
  removeId: (id) =>
    set((state) => ({ ids: state.ids.filter((existingId) => existingId !== id) })),
  clearIds: () => set({ ids: [] }),
}));
