import styled from "styled-components";

export const GridTwoColumn = styled.div`
    display: grid;

    position: fixed;

    grid-template-columns: 70px auto;
    grid-template-rows: 60px auto;

    grid-template-areas:
    "Menu topbar"
    "Menu children";
    width: 100vw;
    height: 100vh;
`;