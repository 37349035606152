import styled from "styled-components";

export const CustomAccordion = styled.div`
  border: 1px solid #E9ECEF;
  border-radius: 3px;
  margin-bottom: 8px;
  overflow: hidden;
`;

export const CustomAccordionHeader = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%; /* Garante que ocupa todo o espaço disponível */
  box-sizing: border-box; /* Inclui padding e border no tamanho total */
  transition: background-color 0.3s ease; /* Suaviza a transição */

  &:hover{
    background-color: #E9ECEF;
  }
`;

export const CustomAccordionContent = styled.div`
    padding: 10px;
    border-top: 1px solid #c8c8c8;
`;