import { useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListArchives from "../../../../../components/Archives/ListArchives";
import ViewHTML from "../../../../../components/ViewHTML";
import { SessionZonaContext } from "../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../contexts/Zone/SessionZone/type";
import { ZonaContext } from "../../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../../contexts/Zone/Zone/type";
import {
  Button,
  Dropdown,
  EditorText,
  Input,
  Progress,
} from "../../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import LudzLoading from "../../../../../ludz.one_ui/Loading";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { useFetchZoneSessionsContents } from "../../../../../ludzoneSDK/Zone/Session/InsiderSession/query";
import { useFetchZoneSessionsBff } from "../../../../../ludzoneSDK/Zone/Session/query";
import { PropsSessionContent } from "../../../../../ludzoneSDK/Zone/Session/type";
import queryClient from "../../../../../services/react-query";
import { Column, Row } from "../../../../../styles/globalStyles";
import ChannelContentPage from "../../../../Channel/Content";
import { ContentStyle } from "./style";
import ReactPlayer from "react-player";

export const ChannelCompletState = () => {
  const [contentSession, setcontentSession] = useState<
    PropsSessionContent | undefined
  >();
  const [sessionZone, setSessionZone] = useState<any | undefined>();
  const [contentId, setContentId] = useState();
  const [deleteContent, setdelete] = useState(false);
  const [loading, setLoading] = useState(true);

  const { id, idsessioncontent } = useParams();

  const { data: session, isLoading: loadingSessionsBff } =
    useFetchZoneSessionsBff(parseInt(id!));
  const { data: content, isLoading: loadingSessionsContents } =
    useFetchZoneSessionsContents(
      idsessioncontent ? parseInt(idsessioncontent!) : contentId
    );

  useEffect(() => {
    if (loading) {
      queryClient.removeQueries("UseZoneSessionBff");
      queryClient.removeQueries("UseZoneSessionContents");
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    setcontentSession(undefined);
    if (content) {
      setcontentSession(content);
    }
    if (session) {
      setSessionZone(session);
      setContentId(session[0]?.contents[0]?.id);
    }
  }, [content, session]);

  return {
    contentSession,
    sessionZone,
    deleteContent,
    setdelete,
    loadingSessionsBff,
    loadingSessionsContents,
  };
};

const Content = () => {
  const {
    handleEditSessionContent,
    schema,
    deleteSessionContent,
    session,
    handleChangeIndexSessionContent,
  } = useContext(SessionZonaContext) as SessionZonaContextType;

  const { id } = useParams();

  const editorText = (e: any, setFieldValue: any) => {
    setFieldValue("textContent", e.editor.getHTML());
  };

  const props = ChannelCompletState();
  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;
  const [edit, setedit] = useState(false);

  const matches = useMediaQuery("(max-width: 520px)");
  const matchesSmall = useMediaQuery("(min-width: 800px)");
  const matcheslarge = useMediaQuery("(min-width: 1400px)");
  const matchesExtraSmall = useMediaQuery("(min-width: 600px)");

  if (props.loadingSessionsContents && props.loadingSessionsBff)
    return <LudzLoading />;

  const listSesstion = session
    ?.find((p) => p.id === props?.contentSession?.session_fk)
    ?.contents.map((sessio, index) => {
      return {
        name:
          props?.contentSession?.index === index
            ? `${index + 1 + " - " + sessio.title! + " (Atual)"}`
            : `${index + 1 + " - " + sessio.title!} `,
        index: index,
      };
    });

  return (
    <>
      <ContentStyle>
        {props?.contentSession ? (
          <>
            <Formik
              key={props.contentSession?.id}
              initialValues={{
                title: props?.contentSession?.title ?? "Sem titulo",
                index: listSesstion?.find(
                  (p) => p.index === props.contentSession?.index
                ),
                textContent:
                  props.contentSession?.textContent ?? "Escreva aqui",
              }}
              validationSchema={schema}
              onSubmit={(values) => {
                handleEditSessionContent(
                  {
                    title: values.title,
                    textContent:
                      values.textContent === "" ? null : values.textContent,
                  },
                  props.contentSession?.id!
                );
                if (values.index?.index !== props.contentSession?.index) {
                  handleChangeIndexSessionContent({
                    newIndex: values.index?.index!,
                    sessionContentId: props.contentSession?.id!,
                  });
                }
                setedit(!edit);
              }}
              enableReinitialize
            >
              {({ values, setFieldValue, handleChange, errors, touched }) => {
                return (
                  <Form>
                    {edit ? (
                      <>
                        <Row
                          style={{ justifyContent: matches ? "center" : "" }}
                        >
                          <Button
                            icon="pi pi-save"
                            type="submit"
                            title={matches ? "" : "Salvar"}
                            pt={{
                              icon: {
                                style: { marginRight: matches ? "0px" : "8px" },
                              },
                            }}
                          />
                          <Button
                            style={{ paddingLeft: matches ? "0px" : "16px" }}
                            title={matches ? "" : "Cancelar"}
                            icon={matches ? "pi pi-times" : ""}
                            type="button"
                            severity="danger"
                            onClick={() => {
                              setedit(!edit);
                            }}
                          />
                        </Row>
                        <Padding padding="8px" />
                      </>
                    ) : null}
                    <Row id="space-between">
                      {edit ? (
                        <Column style={{ width: "100%" }}>
                          <label>Título do conteúdo</label>
                          <Padding />
                          <Input
                            value={values.title}
                            name="title"
                            onChange={handleChange}
                          />{" "}
                          {errors.title && touched.title ? (
                            <div style={{ color: "red" }}>
                              {errors.title.toString()}
                            </div>
                          ) : null}
                          <Padding padding="8px" />
                          <label>Posição do conteúdo</label>
                          <Padding />
                          <Dropdown
                            options={listSesstion}
                            value={values.index}
                            name="index"
                            onChange={handleChange}
                            optionLabel="name"
                          />
                        </Column>
                      ) : (
                        <h2>{props.contentSession?.title ?? "Sem titulo"}</h2>
                      )}
                      {!matches && <Padding />}
                      {adminorowner && !edit ? (
                        <Column id="center">
                          <Row
                            style={{
                              flexDirection: matches ? "column" : "row",
                            }}
                          >
                            <Button
                              type="button"
                              icon="pi pi-pencil"
                              text
                              onClick={() => {
                                setedit(!edit);
                              }}
                            />
                            <Button
                              type="button"
                              icon="pi pi-trash"
                              text
                              onClick={() => props.setdelete(true)}
                            />
                          </Row>
                        </Column>
                      ) : null}
                    </Row>
                    <Padding padding="8px" />
                    {!edit && <hr className="mb-3" />}
                    {props.contentSession?.original_name ? (
                      <>
                        <ListArchives item={props.contentSession!} />
                        <Padding padding="8px" />
                      </>
                    ) : props.contentSession?.playlist_content?.id ? (
                      <ChannelContentPage
                        id={props.contentSession?.playlist_content.id}
                      />
                    ) : null}
                    {props.contentSession?.video_url && (
                      <Padding padding="4px 4px 20px 4px">
                        <ReactPlayer
                          url={
                            props?.contentSession?.video_url ??
                            props?.contentSession?.title
                          }
                          controls
                          width={"100%"}
                          height={
                            matcheslarge
                              ? 520
                              : matchesSmall
                              ? 420
                              : matchesExtraSmall
                              ? 380
                              : 260
                          }
                        />
                      </Padding>
                    )}
                    {props.contentSession?.playlist_content?.id ? null : (
                      <>
                        {edit ? (
                          
                          <>
                          <label>Descrição do conteúdo</label>
                          <Padding padding="8px" />
                          <EditorText
                            value={values.textContent}
                            name="textContent"
                            onTextChange={(e: any) =>
                              editorText(e, setFieldValue)
                            }
                          />
                          </>
                        ) : (
                          <ViewHTML item={props.contentSession?.textContent!} />
                        )}
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <Progress />
        )}
      </ContentStyle>
      <ConfirmationDialog
        visible={props.deleteContent}
        accept={() =>
          deleteSessionContent(props.contentSession?.id?.toString()!, id)
        }
        message="Deseja deletar o conteúdo?"
        onHide={() => {
          props.setdelete(false);
        }}
        reject={() => {
          props.setdelete(false);
        }}
      />
    </>
  );
};

export default Content;
