import { useMediaQuery } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { handleSendMessage, handleSendMessageFile } from "../../../ludzoneSDK/IA/request";
import { systemgpt } from "./sytem.gpt";
import useChatToast from "../../ChatGPT/ChatToast";
import { Modal } from "../../../ludz.one_ui";
import ChatGPT from "../../ChatGPT";
import { ChatMessageType } from "../../ChatGPT/ChatMessage/types";
import { getLudzFlowTop, removeLudzFlowTop, setLudzflowTop } from "../../../services/localStorage";

interface Props {
  visible: boolean;
  onOpen: any;
}

const LudzIA = ({ visible, onOpen }: Props) => {
  const matches = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const propsPrivate = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  // Recupera as mensagens do localStorage
  const getStoredMessages = () => {
    const storedMessages = getLudzFlowTop();

    if (storedMessages) {

      return storedMessages;
    }

    return [
      { role: "system", content: systemgpt(propsPrivate.userProvider!) },
    ];
  };

  const [messages, setMessages] = useState<ChatMessageType[]>(getStoredMessages());
  const [inputValue, setInputValue] = useState<string>("");
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const { toast, showSuccess, showError } = useChatToast();

  // Salva as mensagens no localStorage sempre que mudarem
  useEffect(() => {
    setLudzflowTop(messages);
  }, [messages]);

  // Função para limpar o histórico
  const clearHistory = () => {
    removeLudzFlowTop()
    setMessages([{ role: "system", content: systemgpt(propsPrivate.userProvider!), type: "CHAT" }]); // Restaurar estado inicial
  };

  const handleSend = async (text: string, searchWeb: boolean) => {
    if (text.trim()) {
      setLoading(true);
      setMessages([...messages, { content: text, role: "user", type: "CHAT" }]);
      handleSendMessage(
        [...messages, { content: text, role: "user", type: "CHAT" }],
        setMessages,
        setInputValue,
        setLoading,
        searchWeb
      );
      setInputValue("");
      setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, 60);
    }
  };


  const handleSendFile = async (file: any) => {

    if (file) {
      setMessages([...messages, { content: file?.name, role: "user", type: "FILE" }]);
      setLoading(true);
      setInputValue("");
     handleSendMessageFile(file, [...messages, { content: file?.name, role: "user", type: "FILE" }], setMessages, setInputValue, setLoading)
      setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, 60);
    }
  };

  const handleSendButton = async (text: string) => {
    if (text.trim()) {
      setLoading(true);
      setMessages([...messages, { content: text, role: "user", type: "CHAT" }]);
      let content = text;
      if (text === "Organize sua pesquisa acadêmica.") {
        content =
          "Organizar uma pesquisa acadêmica utilizando as funcionalidades da plataforma Ludzone.";
      } else if (text === "Gerencie sua qualidade de vida.") {
        content =
          "Gostaria de melhorar minha qualidade de vida utilizando o Ludzone como ferramenta.";
      } else if (text === "Crie um Curso Online") {
        content =
          "Organizar um curso online na plataforma Ludzone, incluindo a criação de uma Zona para o curso, módulos com conteúdo programático, Subzonas para trabalhos em grupo, definição de metas e tarefas, e utilização do quadro de tarefas para gerenciamento das atividades dos grupos.";
      } else if (text === "Gerenciar uma consultoria Empresarial") {
        content =
          "Como uso o Ludzone para uma consultoria empresarial junto a meus clientes.";
      } else if (text === "Desenvolva ideias criativas para projetos.") {
        content = "Como posso usar o Ludzone afim de desenvolver ideias criativas para projetos."
      } else if (text === "Facilite dinâmicas de grupo e workshops.") {
        content = "Como faço para utilizar o Ludzone de maneira que Facilite dinâmicas de grupo e workshops."
      }
      handleSendMessage(
        [...messages, { content, role: "user", type: "CHAT" }],
        setMessages,
        setInputValue,
        setLoading
      );
      setInputValue("");
      setTimeout(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, 60);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(showSuccess, showError);
  };

  const getEmailShareLink = (text: string) => {
    const subject = encodeURIComponent("Compartilhamento de Texto da IA");
    const body = encodeURIComponent(text);
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const handleShare = (text: string) => {
    if (navigator.share) {
      navigator
        .share({ title: "Compartilhamento de Texto da IA", text })
        .catch((error) => console.log("Erro ao compartilhar:", error));
    } else {
      window.location.href = getEmailShareLink(text);
    }
  };

  const optionsAwnsers = [
    {
      icon: "pi pi-graduation-cap",
      color: "#76D0EB",
      awnser: "Organize sua pesquisa acadêmica.",
    },
    {
      icon: "pi pi-pen-to-square",
      color: "#CF94D3",
      awnser: "Gerencie sua qualidade de vida.",
    },
    {
      icon: "pi pi-book",
      color: "#E2C541",
      awnser: "Crie um Curso Online",
    },
    {
      icon: "pi pi-briefcase",
      color: "#ED6262",
      awnser: "Gerenciar uma consultoria Empresarial",
    },
    // Novas opções:
    {
      icon: "pi pi-lightbulb",
      color: "#FFD700",
      awnser: "Desenvolva ideias criativas para projetos.",
    },
    {
      icon: "pi pi-users",
      color: "#00BFFF",
      awnser: "Facilite dinâmicas de grupo e workshops.",
    },
  ];


  return (
    <Modal
      visible={visible}
      onOpen={onOpen}
      style={{ width: matches ? "90vw" : "70vw", height: "90vh" }}
    >
      <ChatGPT
        messages={messages}
        isAcesso={true}
        handleCopy={handleCopy}
        handleShare={handleShare}
        handleSendButton={handleSendButton}
        handleSend={handleSend}
        inputValue={inputValue}
        setInputValue={setInputValue}
        loading={loading}
        setLoading={setLoading}
        optionsAwnsers={optionsAwnsers}
        toast={toast}
        chatBoxRef={chatBoxRef}
        clearHistory={clearHistory}
        topbar={true}
        handleSendFile={handleSendFile}
      />
      {/* <Container>
        <Toast ref={toast} position="center" />
        <ChatBox ref={chatBoxRef}>
          {messages.length > 1 ? (
            messages.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                index={index}
                userImage={propsPrivate?.userProvider?.aws.url}
                handleCopy={handleCopy}
                handleShare={handleShare}
              />
            ))
          ) : (
            <div className="flex flex-column" style={{ alignItems: "center", marginTop: "4%" }}>
              <img src={LogoLudz} alt="Imagem de logo" style={{ height: "40px" }} className="mb-4 lg:mb-6" />
              <ChatOptions handleSendButton={handleSendButton} optionsAwnsers={optionsAwnsers}  />
            </div>
          )}
        </ChatBox>
        {loading && (
          <Row id="start">
            <Lottie style={{ marginBottom: "8px", height: "50px" }} className="lottie-avatar" animationData={LoadingIA} />
          </Row>
        )}
        <ChatInput inputValue={inputValue} setInputValue={setInputValue} handleSend={handleSend} />
      </Container> */}
    </Modal>
  );
};

export default LudzIA;
