import { ChangeEvent, useRef, useState } from "react";
import { Button } from "../../../ludz.one_ui";
import { InputContainer } from "../IAstyles";

interface ChatInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSend: (text: string, searchWeb: boolean) => Promise<void>
  loading: boolean | undefined;
  handleSendFile?: (text: string) => void
}

const ChatInput = ({ inputValue, setInputValue, handleSend, loading, handleSendFile }: ChatInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [searchWeb, setSearchWeb] = useState(false)

  const fileInputRef = useRef<any>(null);

  const handleButtonClick = () => {
    // Dispara o clique no input de arquivo oculto
    fileInputRef.current.click();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 120) {
        textareaRef.current.style.height = "120px";
        textareaRef.current.style.overflowY = "auto";
      } else {
        textareaRef.current.style.overflowY = "hidden";
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleSendMessage = () => {
    handleSend(inputValue, searchWeb);
    setInputValue(""); // Limpa o input após o envio
    adjustTextareaHeight(); // Redimensiona o textarea
  };

  // const handleFileChange = (event: any) => {
   
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader: any = new FileReader();
  //     reader.onload = async () => {
  //       const typedArray = new Uint8Array(reader.result);
  //       const pdf = await pdfjsLib.getDocument(typedArray).promise;
  //       let text = '';
  //       for (let i = 1; i <= pdf.numPages; i++) {
  //         const page = await pdf.getPage(i);
  //         const content = await page.getTextContent();
  //         const pageText = content.items.map((item: any) => item.str).join(' ');
  //         text += pageText + '\n';
  //       }
  //       setInputValue(text);
  //       adjustTextareaHeight();
  //     };
  //     reader.readAsArrayBuffer(file); // Lê o arquivo como buffer
  //   }
  // };



  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const file = event.target.files[0];
      await handleSendFile!(file)
      // if (file) {

        
      //   const reader = new FileReader();
      //    reader.onload = async (e:any) => {
      //     adjustTextareaHeight(); // Redimensiona o textarea
      //   };
      //   reader.readAsText(file); // Lê o arquivo como texto
      // }
    }
    
  };


  return (
    <InputContainer
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        borderRadius: "10px",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f7f7f8",
      }}
    >
      <Button
        onClick={handleButtonClick}
        tooltip="Anexe um arquivo"
        disabled={false} // Ajuste conforme necessário
        style={{
          marginRight: "8px",
          minWidth: "40px",
          height: "40px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          color: "white",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        }}
        icon="pi pi-paperclip"
      />
       <Button
        onClick={() => setSearchWeb(!searchWeb)}
        tooltip="Buscar na internet"
        disabled={false} // Ajuste conforme necessário
        style={{
          marginRight: "8px",
          minWidth: "40px",
          height: "40px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: searchWeb ? "#4c8baf" : "",
          padding: "10px",
          color: "white",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        }}
        icon="pi pi-globe"
      />

      {/* Input de arquivo oculto */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".txt,.pdf,.docx" // Tipos de arquivo permitidos
      />
      <textarea
        ref={textareaRef}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSendMessage()}
        placeholder="Escreva sua mensagem aqui..."
        style={{
          resize: "none",
          overflowY: "hidden",
          width: "100%",
          minHeight: "40px",
          maxHeight: "120px",
          border: "none",
          outline: "none",
          padding: "12px",
          fontSize: "16px",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      />
      <Button
        onClick={handleSendMessage}
        style={{
          marginLeft: "8px",
          minWidth: "40px",
          height: "40px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          color: "white",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        }}
        icon={loading ? "pi pi-stop-circle" : "pi pi-send"}
      />
    </InputContainer>
  );
};

export default ChatInput;
