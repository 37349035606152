import ReactPlayer from "react-player";
import { ChannelContentState } from "../../../contexts/Channel/Content/state";
import { Icon } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { formatDate } from "../../../services/functionGlobal";
import { Row } from "../../../styles/globalStyles";
import Archives from "../ChannelComplet/Archives";
import { useMediaQuery } from "@mui/material";

const ChannelContentPage = ({ id }: { id: number }) => {
  const props = ChannelContentState(id);
  const matchesSmall = useMediaQuery("(min-width: 800px)");
  const matcheslarge = useMediaQuery("(min-width: 1400px)");
  const matchesExtraSmall = useMediaQuery("(min-width: 600px)");
  return (
    <Row>
      <div className="w-full">
      
        <h3>{props?.contentChannel?.name}</h3>
        <Padding padding="8px" />
        <Row id="center">
          <ReactPlayer
            url={props?.contentChannel?.video_url ?? props?.contentChannel?.aws_2.url}
            controls
            width={"100%"}
            height={matcheslarge ? 520 :matchesSmall ? 420 : matchesExtraSmall ? 380 : 260}
          />
        </Row>
        <Padding />
        <Padding padding="8px" />
        <h4>Descrição</h4>
        <Padding padding="8px" />
        <p>{props?.contentChannel?.description}</p>
        <Padding padding="8px" />
        <Row>
          <Icon icon="pi pi-clock" size="0.8rem" />
          <h6>{formatDate(props?.contentChannel?.createdAt!)}</h6>
        </Row>
        <Archives data={props?.contentChannel} />
      </div>
    </Row>
  );
};

export default ChannelContentPage;
