import styled from "styled-components";


/* Dropdown menu */
export const DropdownMenu = styled.div`
    background: white;
    border: 1px solid gray;
    border-radius: 0.7rem;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    color: black;
    overflow: auto;
    padding: 0.4rem;
    position: relative;
  
    button {
      align-items: center;
      background-color: transparent;
      border-radius: 10px;
      border: 1px solid #CCC;
      padding: 4px;
      display: flex;
      gap: 0.25rem;
      text-align: left;
      width: 100%;
  
      &:hover,
      &:hover.is-selected {
        background-color: rgb(93, 93, 94);
        color: #fff;
        font-weight: 600;
      }
  
      &.is-selected {
        background-color: #c3c3c3;
      }
    }
` 

  