import styled from "styled-components";

export const Container = styled.div`
  word-break: normal;
  ::-webkit-scrollbar {
    display: none;
  }
  .tiptap {
    min-height: 64px;

     span.mention {
      border-radius: 0.4rem;
      box-decoration-break: clone;
      color: #1C82D5;
      font-weight: 600;
    }

    &:focus-visible {
      outline: none;
    }

    > * + * {
      margin-top: 0.75em;
    }

    ul[data-type="taskList"] {
      list-style: none;
      padding: 0;

      p {
        margin: 0;
      }

      li {
        display: flex;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
          user-select: none;
        }

        > div {
          flex: 1 1 auto;
        }

        ul li,
        ol li {
          display: list-item;
        }

        ul[data-type="taskList"] > li {
          display: flex;
        }
      }
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background: rgba(#ffffff, 0.1);
      color: rgba(#ffffff, 0.6);
      border: 1px solid rgba(#ffffff, 0.1);
      border-radius: 0.5rem;
      padding: 0.2rem;
    }

    pre {
      background: rgba(#ffffff, 0.1);
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
        border: none;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin-left: 0;
      padding-left: 1rem;
      border-left: 2px solid rgba(#ffffff, 0.4);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#ffffff, 0.1);
      margin: 2rem 0;
    }
  }

  ul li {
    list-style: disc;
    margin-left: 40px;
  }

  ol li {
    list-style: decimal;
    margin-left: 40px;
  }

  li {
    font-size: large;
  }

  @media (max-width: 520px) {
    li {
      font-size: medium;
    }
  }

  @media (max-width: 360px) {
    li {
      font-size: small;
    }
  }
`;
