import { Grid, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import "primeicons/primeicons.css";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { Button, EditorText, Modal, Tooltip } from "../../../ludz.one_ui";
import { ButtonPrimeIcon } from "../../../ludz.one_ui/Buttons/ButtonPrimeIcon";
import Chip from "../../../ludz.one_ui/Chip";
import LudzFileUpload from "../../../ludz.one_ui/FileUpload";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import LudzModalMentions from "../../Mentions/ModalMentions";
import { AreaOptions, ButtonAdd, NewPost } from "./style";
import { Toast } from "primereact/toast";
import { useMentionStore } from "../../../zustand/Mentions/zustand";

interface PropsWriteHere {
  initialValues: any;
  handle: (data: any, id: number) => void;
  id?: number;
  setFile?: any;
  members?: any;
  isZone?: boolean | undefined
}

const WriteHere = ({
  initialValues,
  handle,
  id,
  setFile,
  members,
  isZone,

}: PropsWriteHere) => {
  const [display, setDisplay] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [mentions, setMentions] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const clearIds = useMentionStore((state) => state.clearIds);
  const ids = useMentionStore((state) => state.ids);


  const matches = useMediaQuery("(max-width: 375px)");
  const matchesMobileMedium = useMediaQuery("(max-width: 520px)");

  const membersAll = [...members, { user: { id: 99999, username: "everyone" }}]; // Adiciona o usuário "everyone" ao array de membros

  const toastMention = useRef<Toast>(null);

  const show = () => {
    toastMention.current?.show({ severity: 'warn', summary: 'Atenção!', detail: 'Membro já foi marcado na postagem!' });
  };

  const schema = Yup.object().shape({
    content: Yup.string().required("A descrição é obrigatória"),
  });

  const editorText = (e: any, setFieldValue: any, values: any) => {
    const currentContent = e.editor.getHTML();
    const charCount = e.editor.getCharacterCount();

    if (charCount <= 2000) {
        setFieldValue("content", currentContent);

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = currentContent;
        const textContent = tempDiv.textContent || "";

        const updatedMentions = values.mentions.filter((mentionId: any) => {
            const user = members.find((member: any) => member?.user?.id === mentionId);
            return user && textContent.includes(`@${user?.user?.username}`);
        });

        // Lógica para lidar com a menção do "everyone"
        const everyoneMentioned = textContent.includes(`@everyone`);
        const everyoneId = 99999; // ID do "everyone"

        if (everyoneMentioned) {
            members.forEach((member: any) => {
                const memberId = member?.user?.id;
                if (memberId && !updatedMentions.includes(memberId)) {
                    updatedMentions.push(memberId);
                }
            });
        } else {
            // Remove o "everyone" e todos os IDs de members se o "everyone" não estiver mais mencionado
            const filteredMentions = updatedMentions.filter((mentionId: any) => mentionId !== everyoneId);
            updatedMentions.length = 0; 
            updatedMentions.push(...filteredMentions); 
        }

        if (JSON.stringify(updatedMentions) !== JSON.stringify(values.mentions)) {
            setFieldValue("mentions", updatedMentions);
            clearIds();
            updatedMentions.forEach((mentionId: any) => {
                if (!ids.includes(mentionId)) ids.push(mentionId);
            });
        }

        // Atualizar contagem de caracteres
        setCharCount(textContent.length);
    } else {
        // Truncar conteúdo excedente
        const truncatedContent = values.content.slice(0, -1);
        e.editor.commands.setContent(truncatedContent);
        setFieldValue("content", truncatedContent);
        setCharCount(2000);
    }
};

  

  const handleEveryoneMention = (values: any, setFieldValue: any) => {
    const everyoneId = 99999;
    if (values.mentions.includes(everyoneId)) {
      console.log("O usuário 'everyone' já foi marcado!", values.mentions);
      
     
      const allUserIds = members.map((member: any) => member?.user?.id);
  
      
      setFieldValue("mentions", Array.from(new Set([...allUserIds])));
  
      console.log("Todos os membros foram marcados!", allUserIds);
    }
  };
  

  const [visible, setVisible] = useState(false);
  const editorRef = useRef<any>(null);

  return (
    <Column style={{ width: "100%" }}>
      <ButtonAdd onClick={() => setDisplay(true)} display={display}>
        <p style={{ marginTop: "10px", marginBottom: "10px" }}>
          Escreva algo na {isZone ? 'Zona' : 'Subzona' }
        </p>
      </ButtonAdd>
      <NewPost display={display}>
        <Formik
          initialValues={initialValues}
          onSubmit={(value, { resetForm, setFieldValue }) => {
            handleEveryoneMention(value, setFieldValue); 
            handle(value, id!);
            setDisplay(false);
            resetForm();
            setModalFile(!modalFile);
            setFile(undefined);
            setCharCount(0);
            setFieldValue("content", "");
            setFieldValue("mentions", []);
            clearIds()
            editorRef.current?.clearContent();
            console.log(value)
          }}
          validationSchema={schema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            const RemoveMarcation = (index: number) => {
              const filterDelete = [
                ...values.mentions.slice(0, index),
                ...values.mentions.slice(index + 1),
              ];
              setFieldValue("mentions", filterDelete);
            };

            console.log("Valores atualizados de mentions:", values.mentions);
            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  {/* <Grid container spacing={1}>
                    {values?.mentions?.map((item: any, index: number) => {
                      const filterUser = membersAll?.find(
                        (props: any) => props?.user?.id === item
                      );

                      return (
                        <Grid item key={index}>
                          <Chip
                            onClick={() => RemoveMarcation(index)}
                            avatar={filterUser?.user?.aws?.url}
                            title={filterUser?.user?.username}
                          />
                        </Grid>
                      );
                    })}
                  </Grid> */}
                </Row>
                <Padding />
                <Toast ref={toastMention}/>
                <EditorText
                  ref={editorRef}
                  value={values.content}
                  name="content"
                  members={membersAll}
                  onChange={setFieldValue}
                  onTextChange={(e: React.ChangeEvent<HTMLInputElement>) => editorText(e, setFieldValue, values)}
                  show={show}
                />
                <p className="font-semibold">{charCount} / 2000</p>
                {mentions &&
                <LudzModalMentions
                  onClose={() => setMentions(false)}
                  members={membersAll}
                  value={values.content}
                  onChange={setFieldValue}
                  ids={values.mentions}
                />
                }
                <Padding />
                {(errors.content && touched.content) &&  
                <div style={{ color: "red" }}>
                  {errors.content.toString()}
                </div>}
                {modalFile &&
                 <Padding padding="10px 0">
                    <LudzFileUpload setFile={setFile} />
                  </Padding>
                }
                
                <AreaOptions>
                  <Row id="start"
                    style={{ marginBottom: matchesMobileMedium ? '10px' : '' }}
                  >
                    
                    <ButtonPrimeIcon
                      title="upload arquivo"
                      className="pi pi-file-import"
                      fontSize="1.2rem"
                      onClick={() => {
                        setModalFile(!modalFile);
                        setFile(undefined);
                      }}
                    ></ButtonPrimeIcon>
                    {/* <ButtonPrimeIcon
                      title="marcação"
                      className="pi pi-at"
                      fontSize="1.2rem"
                      onClick={() => {
                        if (validateContentBeforeMention(values.content)) {
                          setMentions(!mentions);
                          setFieldValue("content", values.content + "@");
                        } else {
                          show();
                        }
                      }}
                    ></ButtonPrimeIcon> */}
                  </Row>
                  {matches &&
                  <>
                    <Padding padding="10px" />
                  </>
                  }
                  <Row id="end">
                    <Tooltip title="Cancelar">
                      <Row>
                        <Button
                          icon="pi pi-times"
                          type="button"
                          style={{ width: "100%", borderRadius: "10px", padding: '15px' }}
                          severity="danger"
                          className="h-3rem"
                          onClick={() => {
                            setDisplay(false);
                            setModalFile(false);
                          }}
                        />
                      </Row>
                    </Tooltip>
                    <Tooltip title="Postar">
                      <Row>
                        <Button
                          style={{ width: "100%", borderRadius: "10px", padding: '15px'}}
                          type="submit"
                          className="h-3rem"
                          icon="pi pi-send"
                        />
                      </Row>
                    </Tooltip>
                  </Row>
                </AreaOptions>
              </form>
            );
          }}
        </Formik>
      </NewPost>
      <Modal visible={visible} onOpen={() => setVisible(!visible)} />
    </Column>
  );
};

export default WriteHere;