import { Message } from "../../contexts/PrivateRoutes/types";
import http from "../../services/api";
import { uploadArchive } from "../../services/upload-archives";


export const handleSendMessage = async (
  messages: Message[],
  setMessages: any,
  setInput: any,
  setLoading?: any,
  search?: boolean
) => {
  if (search) {
    http
      .get(
        "/bff/google-bff/search?search=" + messages[messages.length - 1].content,
      )
      .then((response) => {

        console.log(response)
        setMessages([
          ...messages,
          {role: "assistant", content: response.data.gptResponse, type: "CHAT", fonts: response.data.finalItems }
        ]);

        // Clear the input field
        setInput("");
        setLoading(false);
      })
      .catch((error) => alert(error));


  } else {

    await http
      .post(
        "/bff/gpt-bff/send", { messages: messages.filter(props => props.type !== 'FILE') }
      )
      .then((response) => {
        setMessages([
          ...messages,
          { ...response.data, type: "CHAT" }
        ]);

        // Clear the input field
        setInput("");
        setLoading(false);
      })
      .catch((error) => alert(error));
  }

  // Update the conversation history with the response from ChatGPT
};


export const handleSendMessageFile = async (
  file: any,
  messages: any,
  setMessages: any,
  setInput: any,
  setLoading?: any
) => {

  console.log(messages)
  const handle = (response: any) => {
    setMessages([
      ...messages,
      { ...response.data, type: "CHAT" }
    ]);
    setInput("");

  }

  return await uploadArchive(file, "/bff/gpt-bff/send-file", {}, "POST", handle).then((response) => {


    console.log(response)
    // Clear the input field
    setInput("");
    setLoading(false);
  })
    .catch((error) => alert(error));



  // Update the conversation history with the response from ChatGPT
};
