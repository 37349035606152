import {
  BubbleMenu,
  EditorContent,
  FloatingMenu,
  ReactRenderer,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useLocation, useParams } from "react-router-dom";
import React, { forwardRef, useImperativeHandle } from "react";
import {
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiH1,
  RiH2,
  RiH3,
  RiListCheck,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
} from "react-icons/ri";
import {
  RxFontBold,
  RxFontItalic,
  RxStrikethrough,
  RxUnderline,
} from "react-icons/rx";
import BubbleButtons from "./BubbleMenu/BubbleButton";
import { Container } from "./style";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import { ColorPicker } from "primereact/colorpicker";
import { Column } from "../../styles/globalStyles";
import BubbleLink from "./BubbleMenu/BubbleLink";
import TextAlign from "@tiptap/extension-text-align";
import CodeBlock from "@tiptap/extension-code-block";
import Underline from "@tiptap/extension-underline";
import Mention from '@tiptap/extension-mention'
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";

import tippy from "tippy.js";
import MentionList from "./Mentions/MentionList";



// const extensions = [StarterKit];

interface PropsEditorText {
  name?: string;
  value: any;
  onSubmit?: React.FormEventHandler<HTMLDivElement> | undefined;
  onTextChange?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  required?: boolean | undefined;
  style?: React.CSSProperties;
  members?: any;
  onChange?: any; // Função para atualizar conteúdo e menções
  show?: () => void;
}

const EditorText = forwardRef(
  (
    {
      value,
      onTextChange,
      onKeyDown,
      onSubmit,
      name,
      required,
      style,
      members,
      onChange,
      show
    }: PropsEditorText,
    ref
  ) => {
    // const content = value;
    const location = useLocation();

    const { id, idSubzone } = useParams()

    const enableMentions = [`/zona/${id}/mural`,`/zona/${id}/subzona/${idSubzone}/mural`].includes(location.pathname);

    const extensions = [
  StarterKit,
  CodeBlock.configure({
    languageClassPrefix: "language-",
  }),
  ListItem,
  TextStyle,
  Underline,
  Color,
  TextAlign.configure({
    types: ["heading", "paragraph"], // Define os tipos de nós que podem ser alinhados
  }),

  BulletList.configure({
    keepMarks: true,
    keepAttributes: true,
  }),
  Color,
  OrderedList,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),

  Heading.configure({
    levels: [1, 2, 3],
  }),
  Link.configure({
    autolink: false,
  }),
];

if (enableMentions) {
  extensions.push(
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion: {
        items: ({ query }: { query: string }) => {
          return members
        .filter((member: any) => member.user.username.toLowerCase().includes(query.toLowerCase()))
        .map((member: any) => ({
          id: member.user.id,
          label: member.user.username,
          avatar: member?.user.aws?.url, // Se houver imagem de perfil
        }));
        },
        render: () => {
          let component: any;
          let popup: any;
    
          return {
            onStart: (props) => {
              component = new ReactRenderer(MentionList, {
                props: {
                  ...props,
                  members, // Passa `members` como prop para o MentionList
                  value, // Passa `value`
                  onChange, // Passa `onChange`
                  show
                },
                editor: props.editor,
              });
    
              if (!props.clientRect) {
                return;
              }
    
              popup = tippy("body", {
                getReferenceClientRect: () => {
                  const rect = props.clientRect?.();
                  if (!rect) {
                    return new DOMRect(0, 0, 0, 0); // Garantia de valor válido
                  }
                  return rect;
                },
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              });
            },
            onUpdate: (props) => {
              component.updateProps({
                ...props,
                members, // Atualiza `members` se necessário
                value,
                onChange,
                show
              });
    
              if (!props.clientRect) {
                return;
              }
    
              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              });
            },
            onKeyDown: (props: SuggestionKeyDownProps) => {
              if (props.event.key === 'Escape') {
                popup[0].hide();
                return true;
              }
    
              // Retorna `false` explicitamente, já que o tipo esperado é boolean.
              return false;
            },
            onExit: () => {
              popup[0].destroy();
              component.destroy();
            },
          };
        },
      },
    }) as any,
  )
}

    const editor = useEditor({
      extensions,
      content: value,
      onUpdate: onTextChange,
    });

    useImperativeHandle(ref, () => ({
      clearContent: () => {
        editor?.commands.clearContent();
      },
    }));

    if (!editor) return null;

    return (
      <div>
        <Container className="tiptap" style={{ ...style }}>
          <EditorContent
            name={name}
            editor={editor}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
            value={value}
          />
          <FloatingMenu editor={editor!}>
            Pressione / para navegar pelas opções
          </FloatingMenu>
          <FloatingMenu
            editor={editor!}
            className="BubbleMenu"
            shouldShow={({ state }) => {
              const from = state?.selection;
              const currentLineText = from?.$from.nodeBefore?.textContent;
              return currentLineText === "/";
            }}
          >
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 1 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              icon={<RiH1 size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 2 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              icon={<RiH2 size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 3 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              icon={<RiH3 size={16} />}
            />
            <BubbleButtons
              onClick={() => editor.commands.setParagraph()}
              icon={<RiParagraph size={16} />}
            />

            {/* Font Styles */}
            <BubbleButtons
              isActive={editor.isActive("bold")}
              onClick={() => editor.chain().focus().toggleBold().run()}
              icon={<RxFontBold size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("italic")}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              icon={<RxFontItalic size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("underline")}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              icon={<RxUnderline size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("strike")}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              icon={<RxStrikethrough size={16} />}
            />

            {/* Alignment Options */}
            <BubbleButtons
              onClick={() => editor.chain().focus().setTextAlign("left").run()}
              icon={<RiAlignLeft size={16} />}
            />
            <BubbleButtons
              onClick={() =>
                editor.chain().focus().setTextAlign("center").run()
              }
              icon={<RiAlignCenter size={16} />}
            />
            <BubbleButtons
              onClick={() => editor.chain().focus().setTextAlign("right").run()}
              icon={<RiAlignRight size={16} />}
            />
            <BubbleButtons
              onClick={() =>
                editor.chain().focus().setTextAlign("justify").run()
              }
              icon={<RiAlignJustify size={16} />}
            />

            <BubbleButtons
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive("bulletList")}
              icon={<RiListUnordered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("orderedList")}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              icon={<RiListOrdered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("taskList")}
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              icon={<RiListCheck size={16} />}
            />
              <BubbleLink editor={editor}/>
            {/* Color Picker */}
            <Column
              id="center"
              className=" px-2"
              style={{ backgroundColor: "#E5E6EA" }}
            >
              <ColorPicker
                value={editor.getAttributes("textStyle").color}
                pt={{
                  input: { className: "w-1rem h-1rem" },
                }}
                onChange={(e) =>
                  editor
                    .chain()
                    .focus()
                    .setColor(`#` + e.target.value?.toString()!)
                    .run()
                }
              />
            </Column>
            {/* Code Option */}
            <BubbleButtons
              isActive={editor.isActive("codeBlock")}
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              icon={
                <span style={{ fontFamily: "monospace", fontSize: "14px" }}>
                  {"</>"}
                </span>
              }
            />
          
          </FloatingMenu>
          {editor && (
            <BubbleMenu className="BubbleMenu" editor={editor}>
              {/* <BubbleButtons
              name="Text"
              icon={
                <RxChevronDown
                  size={16}
                  onClick={() => editor.chain().focus().toggleBold().run()}
                />
              }
            /> */}
                <BubbleButtons
              isActive={editor.isActive("heading", { level: 1 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              icon={<RiH1 size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 2 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              icon={<RiH2 size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("heading", { level: 3 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              icon={<RiH3 size={16} />}
            />
            <BubbleButtons
              onClick={() => editor.commands.setParagraph()}
              icon={<RiParagraph size={16} />}
            />

            {/* Font Styles */}
            <BubbleButtons
              isActive={editor.isActive("bold")}
              onClick={() => editor.chain().focus().toggleBold().run()}
              icon={<RxFontBold size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("italic")}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              icon={<RxFontItalic size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("underline")}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              icon={<RxUnderline size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("strike")}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              icon={<RxStrikethrough size={16} />}
            />

            {/* Alignment Options */}
            <BubbleButtons
              onClick={() => editor.chain().focus().setTextAlign("left").run()}
              icon={<RiAlignLeft size={16} />}
            />
            <BubbleButtons
              onClick={() =>
                editor.chain().focus().setTextAlign("center").run()
              }
              icon={<RiAlignCenter size={16} />}
            />
            <BubbleButtons
              onClick={() => editor.chain().focus().setTextAlign("right").run()}
              icon={<RiAlignRight size={16} />}
            />
            <BubbleButtons
              onClick={() =>
                editor.chain().focus().setTextAlign("justify").run()
              }
              icon={<RiAlignJustify size={16} />}
            />

            <BubbleButtons
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive("bulletList")}
              icon={<RiListUnordered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("orderedList")}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              icon={<RiListOrdered size={16} />}
            />
            <BubbleButtons
              isActive={editor.isActive("taskList")}
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              icon={<RiListCheck size={16} />}
            />
             <BubbleLink editor={editor}/>
            {/* Color Picker */}
            <Column
              id="center"
              className=" px-2"
              style={{ backgroundColor: "#E5E6EA" }}
            >
              <ColorPicker
                value={editor.getAttributes("textStyle").color}
                pt={{
                  input: { className: "w-1rem h-1rem" },
                }}
                onChange={(e) =>
                  editor
                    .chain()
                    .focus()
                    .setColor(`#` + e.target.value?.toString()!)
                    .run()
                }
              />
            </Column>
            {/* Code Option */}
            <BubbleButtons
              isActive={editor.isActive("codeBlock")}
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              icon={
                <span style={{ fontFamily: "monospace", fontSize: "14px" }}>
                  {"</>"}
                </span>
              }
            />
           
            </BubbleMenu>
          )}
        </Container>
        {/* <Editor required={required} maxLength={1000} value={value} onSubmit={onSubmit} name={name} pt={{
                toolbar: { style: { backgroundColor: "#eff3f8" } },
                option: { style: { backgroundColor: "#eff3f8" } },
            }} onTextChange={onTextChange} headerTemplate={header} style={{ height: '256px', backgroundColor: "gray" }} onKeyDown={onKeyDown} tabIndex={0} /> */}
      </div>
    );
  }
);

export default EditorText;
