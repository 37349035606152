
import { Calendar } from 'primereact/calendar';
import { Column } from "../../styles/globalStyles";
import { Nullable } from 'primereact/ts-helpers';
import { addLocale } from 'primereact/api';

interface PropsCalendar {
    label?: string, 
    date?: Nullable<Date>,
    onChange?(event: any): void,
    name?: string,
    style?: React.CSSProperties
    dateFormat: string,
    placeholder: any,
    minDate: any

}

const CalendarLudz = ({label, date,onChange, name, style, dateFormat, placeholder, minDate}: PropsCalendar) => {

    addLocale('pt-BR', {
        firstDayOfWeek: 0,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
        monthNames: [
          "janeiro", "fevereiro", "março", "abril", "maio", "junho",
          "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ],
        monthNamesShort: [
          "jan", "fev", "mar", "abr", "mai", "jun",
          "jul", "ago", "set", "out", "nov", "dez"
        ],
        today: "Hoje",
        clear: "Limpar"
      });

    return (
        <Column>
            <label>
                {label}
            </label>
            <Calendar locale="pt-BR" minDate={minDate} placeholder={placeholder} name={name}  value={date} style={{ width: "100%", background: "#F6F7F7", ...style }} onChange={onChange} dateFormat={dateFormat} showIcon />
        </Column>
    )
}

export default CalendarLudz;