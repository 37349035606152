import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { Padding } from "../styles/styled-components";

interface InputTextAttributis {
    label?: string,
    onChange?: any,
    onBlur?: () => void,
    placeholder?: string,
    onKeyUp?: () => void,
    onKeyDown?: any,
    style?: React.CSSProperties,
    value?: string,
    defaultValue?: string,
    type?: any,
    name?: string,
    className?: string,
    accept?: string,
    maxLength?: any,
    rows?: number | undefined
}

const InputTextArea = ({ className, value, onChange, accept,type,name, onBlur, defaultValue, placeholder, style, onKeyUp, onKeyDown, label, rows, maxLength, ...props }: InputTextAttributis) => {
    return (
        <>
            {label ? <>
                <label>{label}</label>
                <Padding padding="2px" />
            </> : null}
            <InputTextarea
                className={className} rows={rows} autoResize value={value} name={name} onKeyDown={onKeyDown} onChange={onChange} onKeyUp={onKeyUp} onBlur={onBlur} style={{ ...style, backgroundColor: "#F6F7F7" }} defaultValue={defaultValue} placeholder={placeholder} maxLength={maxLength}
                {...props}
            />
        </>

    )
}

export default InputTextArea