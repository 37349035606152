import { Item, ItensLink } from "../../../contexts/PrivateRoutes/types";
import { Modal } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import { Container } from "./style";

const ChatFont = ({
  onHide,
  visible,
  itens,
}: {
  visible: boolean;
  onHide: any;
  itens: ItensLink;
}) => {
  return (
    <Modal visible={visible} onOpen={onHide} title={"Fontes"}>
      {itens.map((item, index) => {
        return (
          <Font
            link={item.link}
            title={item.title}
            cse_image={item.cse_image}
            og_description={item.og_description}
          />
        );
      })}
    </Modal>
  );
};

const Font = ({ link, title, cse_image, og_description }: Item) => {
  return (
    <Container
      onClick={() => {
        window.open(link);
      }}
    >
      <img
        src={
          cse_image ??
          "https://cdn-icons-png.freepik.com/256/1006/1006771.png?semt=ais_hybrid"
        }
        alt="logo_site"
      />
      <Padding />
      <Row>
        <Column id="center">
          <h3>{title}</h3>
        </Column>
      </Row>
      <Padding />
      <p>{og_description?.substring(0, 128)}...</p>
    </Container>
  );
};

export default ChatFont;
