import { Form, Formik } from "formik";
import { ChangeEvent, useContext, useState } from "react";
import { ObjectiveKeySubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/context";
import { ObjectiveKeySubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/ObjectiveKeySubzone/type";
import {
  Button,
  Input,
  InputTextArea,
  Modal,
} from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";
import LudzColors from "../../../../../ludz.one_ui/LudzColors";

interface PropsCreateSection {
  visible?: boolean;
  onOpen?: any;
  id?: number;
}

const CreateObjectiveKey = ({ visible, onOpen, id }: PropsCreateSection) => {
  const { initialValue, schema, handleObjectiveKey, setColor } = useContext(
    ObjectiveKeySubzonaContext
  ) as ObjectiveKeySubzonaContextType;
  const [charCount, setCharCount] = useState(0);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharCount(event.target.value.length);
  };

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Criar Tarefa"}>
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          handleObjectiveKey(values, id!, onOpen);
          resetForm();
          setCharCount(0);
        }}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Column>
                <Input
                  name="content"
                  value={values.content}
                  onChange={handleChange}
                  label="Nome da meta"
                />
              </Column>
              <Padding />
              {errors.content && touched.content ? (
                <div style={{ color: "red" }}>{errors.content.toString()}</div>
              ) : null}
              <Padding padding="8px" />
              <Column>
                <InputTextArea
                  maxLength={190}
                  name="description"
                  value={values.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    handleTextChange(e);
                  }}
                  label="Descrição da meta"
                />
                <div
                  className="font-semibold"
                  style={{
                    alignSelf: "flex-end",
                    marginTop: "4px",
                    marginBottom: "6px",
                  }}
                >
                  {charCount}/190
                </div>
              </Column>
              <Padding />
              {errors.description && touched.description ? (
                <div style={{ color: "red" }}>
                  {errors.description.toString()}
                </div>
              ) : null}
              <Padding padding="8px" />
              <Column>
                <h5>Selecione a cor:</h5>
                <Padding padding="8px" />
                <LudzColors setColor={setColor} />
              </Column>
              <Padding padding="8px" />
              <Padding padding="16px" />
              <Row id="center">
                <Button title="Criar" type="submit" />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateObjectiveKey;
